<template>
  <div>
    <div class="header">
      <van-row gutter="10" @click.native="onClick">
        <van-col  span="24">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px;margin:.3rem 0 0 .2rem;"
          />
          <span style="font-size: 25px; color: white;font-weight:bold;margin-left:.1rem">{{title}}</span>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style>

</style>